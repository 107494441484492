<template>
  <div class="top">
    <ul class="top-slides-bg-circles"></ul>
    <ul class="top-slides-bg-circles2"></ul>
    <v-container fluid>
      <div class="top-slides-bg" ref="getSlidesHeight">
        <div class="top-link">
          <v-row class="mt">
            <v-col cols="12"><p class="topic">More Music</p></v-col>
            <v-col cols="1" sm="6" md="8" lg="8" xl="8" xxl="8"></v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4" xxl="4">
              <v-select v-model="selectGenre" :items="genres" class="pulldown"></v-select>
            </v-col>
          </v-row>
          <div class="py-4"></div>
          <v-row>
            <v-col cols="6" sm="4" md="3" lg="3" xl="3" xxl="3" v-for="(item, i) in items" :key="i" class="music-list">
              <v-card  height="90%" class="links" :href="item.url" target="_blank">
                <v-img v-bind:src="item.src"></v-img>
                <v-card-title class="text-left text-subtitle-2 mt-3 pa-0 pl-3" :style="{color: item.color}">{{
                  item.genre
                }}</v-card-title>
                <v-card-title class="music-list-title text-h6 pa-0" style="color: #222">{{
                  item.title
                }}</v-card-title>
                <v-card-text class="music-list-text" style="color: #222">{{
                  item.text
                }}</v-card-text>
                <v-card-text class="text-right text-caption text-bottom" style="color: #777">{{
                  item.date
                }}</v-card-text>
              </v-card>
              <v-btn :disabled="item.story===''" @click="storyClick(item.story);" color="#f8c6c6" class="music-list-story">Story<v-icon color=#222>mdi-menu-right</v-icon></v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="py-12"></div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectGenre: "All Genre",
    genres: [ "All Genre", "MUSIC GAME", "VISUAL NOVEL", "GAME BGM", "FREE GENRE" ],
    musics: [
    {
        url: "https://soundcloud.com/semicolon0103/tabiji-heno-shoukei",
        src: require("../assets/musics/Tabiji_heno_shoukei.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "旅路への憧憬",
        text: "稚内を走りながら",
        date: "2022",
        story: "",
      },
      {
        url: "",
        src: require("../assets/musics/_unpublished.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "未公開曲 #1",
        text: "",
        date: "2022",
        story: "",
      },
      {
        url: "https://d10rama.com/nitousei/",
        src: require("../assets/musics/Nitousei.webp"),
        genre: "VISUAL NOVEL",
        color: "#D81B60",
        title: "みえない二等星",
        text: "ギターとの和解",
        date: "2022",
        story: "",
      },
      {
        url: "https://omoidegyosen.bandcamp.com/album/omoide-world-tour",
        src: require("../assets/musics/Omowide2.webp"),
        genre: "FREE GENRE",
        color: "#FB8C00",
        title: "セピア色の記憶",
        text: "物悲しいピアノ曲",
        date: "2022",
        story: "",
      },
      {
        url: "https://soundcloud.com/semicolon0103/graduate-nekochan",
        src: require("../assets/musics/Graduate_Nekochan.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Graduate Nekochan",
        text: "卒業ソング",
        date: "2022",
        story: "",
      },
      {
        url: "",
        src: require("../assets/musics/Yuuoumaishin.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "勈徃邁進",
        text: "50時間くらいで作った\n和風なやつ",
        date: "2022",
        story: "",
      },
      {
        url: "https://soundcloud.com/semicolon0103/lemonadegrenade",
        src: require("../assets/musics/Lemonadegrenade.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Lemonadegrenade",
        text: "本気でクラシックに\n全振り",
        date: "2021",
        story: "Lemonadegrenade",
      },
      {
        url: "https://soundcloud.com/semicolon0103/frontier-nekochan",
        src: require("../assets/musics/Frontier_Nekochan.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Frontier Nekochan",
        text: "電子音と生楽器の融合",
        date: "2021",
        story: "Frontier_Nekochan",
      },
      {
        url: "https://unityroom.com/games/crazy_about_your_shot",
        src: require("../assets/musics/CrazyAboutYourShot.webp"),
        genre: "GAME BGM",
        color: "#43A047",
        title: "キミの注射に超夢中",
        text: "縦スクロールSTG",
        date: "2021",
        story: "",
      },
      {
        url: "https://d10rama.com/amehazu/",
        src: require("../assets/musics/Amehazu.webp"),
        genre: "VISUAL NOVEL",
        color: "#D81B60",
        title: "雨にして人を外れ",
        text: "ピアノ基調でしっとりと",
        date: "2021",
        story: "",
      },
      {
        url: "https://soundcloud.com/semicolon0103/nightlude",
        src: require("../assets/musics/Nightlude.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Nightlude",
        text: "可憐な舞",
        date: "2021",
        story: "Nightlude",
      },
      {
        url: "",
        src: require("../assets/musics/Knitefight.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Knitefight",
        text: "戦う騎士",
        date: "2021",
        story: "Knitefight",
      },
      {
        url: "",
        src: require("../assets/musics/Glittem.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Glittem",
        text: "オシャンなバー",
        date: "2021",
        story: "Glittem",
      },
      {
        url: "https://soundcloud.com/semicolon0103/suzume-no-sekai",
        src: require("../assets/musics/Suzume_no_sekai.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "スズメノセカイ",
        text: "実は自分を重ねてる",
        date: "2021",
        story: "Suzume_no_sekai",
      },
      {
        url: "https://soundcloud.com/semicolon0103/electrical-toxic",
        src: require("../assets/musics/Electrical_Toxic.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "エレクトリカル\n・トキシック",
        text: "栄枯盛衰を曲に",
        date: "2021",
        story: "Electorical_Toxic",
      },
      {
        url: "https://soundcloud.com/semicolon0103/yumeiro-no-hanataba",
        src: require("../assets/musics/Yumeiro_no_hanataba.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "夢色の花束",
        text: "花束を渡す感じ",
        date: "2021",
        story: "Yumeiro_no_hanataba",
      },
      {
        url: "https://soundcloud.com/semicolon0103/popping-nekochan",
        src: require("../assets/musics/Popping_Nekochan.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Popping Nekochan",
        text: "狂詩曲的な",
        date: "2021",
        story: "Popping_Nekochan",
      },   
      {
        url: "https://soundcloud.com/semicolon0103/bravelgent",
        src: require("../assets/musics/Bravelgent.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Bravelgent",
        text: "襟裳岬イメージ",
        date: "2021",
        story: "Bravelgent",
      },
      {
        url: "https://omoidegyosen.bandcamp.com/album/--2",
        src: require("../assets/musics/Omowide1.webp"),
        genre: "FREE GENRE",
        color: "#FB8C00",
        title: "Eternity Memoria",
        text: "物悲しいピアノ曲",
        date: "2021",
        story: "",
      },
      {
        url: "https://d10rama.com/hitoyane/",
        src: require("../assets/musics/Hitoyane.webp"),
        genre: "VISUAL NOVEL",
        color: "#D81B60",
        title: "ひとつ屋根の下\nなのに",
        text: "わちゃわちゃ\n学園アニメOP感",
        date: "2021",
        story: "",
      },
      {
        url: "https://soundcloud.com/semicolon0103/vermitation",
        src: require("../assets/musics/Vermitation.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Vermitation",
        text: "厨二っぽく",
        date: "2021",
        story: "Vermitation",
      },
      {
        url: "https://soundcloud.com/semicolon0103/voyager-nekochan",
        src: require("../assets/musics/Voyager_Nekochan.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Voyager Nekochan",
        text: "宇宙感あるエンディング",
        date: "2020",
        story: "Voyager_Nekochan",
      },
      {
        url: "https://soundcloud.com/semicolon0103/daydream",
        src: require("../assets/musics/Daydream.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Daydream",
        text: "夢うつつ",
        date: "2020",
        story: "Daydream",
      },
      {
        url: "https://soundcloud.com/semicolon0103/skyhigh-nekochan",
        src: require("../assets/musics/Skyhigh_Nekochan.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Skyhigh Nekochan",
        text: "ただ楽しそうな感じ",
        date: "2020",
        story: "Skyhigh_Nekochan",
      },

      {
        url: "http://www.proc.org.tohoku.ac.jp/?%B8%F8%B3%AB%C3%E6%A4%CE%BA%EE%C9%CA/2020%C7%AF%C5%D9SpringFestival/%A5%CF%A5%B7%A5%EC%A1%AA%A5%AE%A5%E7%A5%EB%A5%A4",
        src: require("../assets/musics/HashireGyorui.webp"),
        genre: "GAME BGM",
        color: "#43A047",
        title: "ハシレ！ギョルイ",
        text: "3Dアクションゲーム",
        date: "2020",
        story: "",
      },

      {
        url: "https://soundcloud.com/semicolon0103/shiftenery-ksm-edit",
        src: require("../assets/musics/Shiftenery.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Shiftenery",
        text: "2日で急遽作ることに",
        date: "2020",
        story: "Shiftenery",
      }, 
      {
        url: "https://soundcloud.com/semicolon0103/cuddle-nekochan",
        src: require("../assets/musics/Cuddle_Nekochan.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Cuddle Nekochan",
        text: "癒しを求めて",
        date: "2020",
        story: "Cuddle_Nekochan",
      },    
      {
        url: "https://soundcloud.com/semicolon0103/affectation",
        src: require("../assets/musics/Affectation.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Affectation",
        text: "カッコよくつくった",
        date: "2020",
        story: "Affectation",
      },
      {
        url: "https://soundcloud.com/semicolon0103/asteroid-nekochan",
        src: require("../assets/musics/Asteroid_Nekochan.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Asteroid Nekochan",
        text: "宇宙っぽい合作曲",
        date: "2019",
        story: "Asteroid_Nekochan",
      },
      {
        url: "https://soundcloud.com/semicolon0103/fierceness_re",
        src: require("../assets/musics/Fierceness.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Fierceness",
        text: "闇落ちあおは",
        date: "2019",
        story: "Fierceness",
      },
      {
        url: "https://soundcloud.com/semicolon0103/bayside-nekochan",
        src: require("../assets/musics/Bayside_Nekochan.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Bayside Nekochan",
        text: "夏全開って感じの",
        date: "2019",
        story: "Bayside_Nekochan",
      },
      {
        url: "https://soundcloud.com/semicolon0103/contemplation",
        src: require("../assets/musics/Contemplation.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Contemplation",
        text: "院試勉強疲れて\n人生諦観してた",
        date: "2019",
        story: "Contemplation",
      },
      {
        url: "https://soundcloud.com/semicolon0103/maiden-nekochan",
        src: require("../assets/musics/Maiden_Nekochan.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Maiden Nekochan",
        text: "乙女心みたいな",
        date: "2019",
        story: "Maiden_Nekochan",
      },
      {
        url: "https://soundcloud.com/semicolon0103/melancholie",
        src: require("../assets/musics/Melancholy.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Melancholy",
        text: "曲名通り",
        date: "2019",
        story: "Melancholy",
      },
      {
        url: "https://www.freem.ne.jp/win/game/19923",
        src: require("../assets/musics/RevengeOfAquaLives2.webp"),
        genre: "GAME BGM",
        color: "#43A047",
        title: "海洋生物たちの復讐2",
        text: "縦スクロールSTG",
        date: "2019",
        story: "",
      },
      {
        url: "https://www.freem.ne.jp/win/game/19807",
        src: require("../assets/musics/IdealMemoria.webp"),
        genre: "GAME BGM",
        color: "#43A047",
        title: "Ideal Memoria",
        text: "ファンタジーRPG",
        date: "2019",
        story: "",
      },
      {
        url: "https://soundcloud.com/semicolon0103/hispeed-nekochan",
        src: require("../assets/musics/Highspeed_Nekochan.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Highspeed Nekochan",
        text: "ノリと勢いだけで",
        date: "2018",
        story: "Highspeed_Nekochan",
      },
      {
        url: "https://soundcloud.com/semicolon0103/operon_re",
        src: require("../assets/musics/Operon.webp"),
        genre: "MUSIC GAME",
        color: "#039BE5",
        title: "Operon",
        text: "作曲引退を賭けて",
        date: "2018",
        story: "Operon",
      },
      {
        url: "http://www.proc.org.tohoku.ac.jp/?%BF%CA%B7%E2%A4%CE%A5%A2%A5%DB%BB%D2%A1%C1%A4%B4%C3%ED%CA%B8%A4%CF%B9%F5%CE%F2%BB%CB%A4%C7%A4%B9%A4%AB%A1%A9%A1%A9%A1%C1",
        src: require("../assets/musics/AttackingAhoGirl.webp"),
        genre: "GAME BGM",
        color: "#43A047",
        title: "進撃のアホ子\n～ご注文は黒歴史\nですか？？～",
        text: "スニーキングゲーム",
        date: "2017",
        story: "",
      },
      {
        url: "",
        src: require("../assets/musics/_unpublished.webp"),
        genre: "GAME BGM",
        color: "#43A047",
        title: "未完成プロジェクト",
        text: "RPGのはずだった",
        date: "2016",
        story: "",
      },
    ],
  }),
  computed: {
    items() {
      if(this.selectGenre === "All Genre"){
        return this.musics
      } else {
        return this.musics.filter((music) => music.genre === this.selectGenre);
      }
    },
  },
  methods: {
    storyClick(id) {
      const url = "/music/" + id;
      this.$router.push(url);
    }
  }
}
</script>

<style scoped>
.top-slides-bg-circles {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #f8c6c6;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}
.top-slides-bg-circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}
.top-slides-bg-circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}
.top-slides-bg-circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}
.top-slides-bg-circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}
.top-slides-bg-circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}
.top-slides-bg-circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}
.top-slides-bg-circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
.top-slides-bg-circles2 {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles2 li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #adb9de;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles2 li:nth-child(1) {
  left: 80%;
  width: 80px;
  height: 80px;
  animation-delay: 6s;
}
.top-slides-bg-circles2 li:nth-child(2) {
  left: 55%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  animation-duration: 14s;
}
.top-slides-bg-circles2 li:nth-child(3) {
  left: 30%;
  width: 20px;
  height: 20px;
  animation-delay: 16s;
}
.top-slides-bg-circles2 li:nth-child(4) {
  left: 15%;
  width: 60px;
  height: 60px;
  animation-delay: 4s;
  animation-duration: 16s;
}
.top-slides-bg-circles2 li:nth-child(5) {
  left: 40%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
@keyframes animate {
  0% {
    transform: translateY(1000px) rotate(0deg);
    opacity: 0.4;
    border-radius: 20%;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0.2;
    border-radius: 40%;
  }
}
</style>
