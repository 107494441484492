import { createRouter, createWebHistory } from 'vue-router'
import MusicView from '../views/MusicView.vue'
import StoryView from '../views/StoryView.vue'
import TripView from '../views/TripView.vue'
import WorksView from '../views/WorksView.vue'
import TopView from '../views/TopView.vue'
import M3View from '../views/M3View.vue'
import DiscographyView from '../views/DiscographyView.vue'
import Error403View from '../views/403View.vue'
import Error404View from '../views/404View.vue'
import Error503View from '../views/503View.vue'
import MoreMusicView from "@/views/MoreMusicView.vue";

const routes = [
  {
    path: '/',
    name: 'top',
    component: TopView
  },
  {
    path: '/music',
    name: 'music',
    component: MusicView
  },
  {
    path: '/more_music',
    name: 'more_music',
    component: MoreMusicView
  },
  {
    path: '/music/:id',
    name: 'story',
    component: StoryView
  },
  {
    path: '/trip',
    name: 'trip',
    component: TripView
  },
  {
    path: '/works',
    name: 'works',
    component: WorksView
  },
  {
    path: '/blog',
    name: 'blog',
    // componentの代わりにbeforeEnterを使うことで外部リンクへのルーティングをしている
    beforeEnter() {
      window.location = "https://semicolon0103.hatenablog.com/"
    }
  },
  {
    path: '/booth',
    name: 'booth',
    // 仕様はblog丸パクリ
    beforeEnter() {
      window.location = "https://semicolon0103.booth.pm/"
    }
  },
  {
    path: '/Melodious_Nekochan',
    name: 'Melodious_Nekochan',
    beforeEnter() {
      window.location = "https://twitter.com/semicolon0103/status/1647540335491309575/"
    }
  },
  {
    path: '/M3',
    name: 'M3',
    component: M3View
  },
  {
    path: '/Discography',
    name: 'Discography',
    component: DiscographyView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/503',
    name: '503',
    component: Error503View
  },
  {
    path: '/403',
    name: '403',
    component: Error403View
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: Error404View
  },
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
