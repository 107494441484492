<template>
  <div class="top">
    <ul class="top-slides-bg-circles"></ul>
    <ul class="top-slides-bg-circles2"></ul>
    <v-container fluid>
      <div class="top-slides-bg" ref="getSlidesHeight">
        <div class="top-link">
          <v-row class="mt">
            <v-col cols="12"><p class="topic">Works</p></v-col>
            <v-col cols="1" sm="6" md="8" lg="8" xl="8" xxl="8" class="pb-0"></v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4" xxl="4" class="pb-0">
              <v-select v-model="selectGenre" :items="genres" class="pulldown"></v-select>
            </v-col>
            <v-col cols="12" v-for="(item, i) in items" :key="i" class="ma-0">
              <v-card v-if="item.divider === ''" color="rgba(250,250,250,0.5)" :href="item.url" class="works-list" target="_blank">
                <div class="d-md-flex junstify-center align-center">
                  <div class="works-list-item">
                    <div class="works-list-item2">
                      <div class="works-list-date">{{ item.date }}</div>
                      <div class="works-list-genre">
                        <v-chip :color=item.color class="works-list-genre-chip">{{ item.genre }}</v-chip>
                      </div>
                    </div>
                    <div class="works-list-title">{{ item.title }}</div>
                  </div>
                  <div class="works-list-text">{{ item.text }}</div>
                </div>
              </v-card>
              <div v-else>
                <p class="works-list-divider">{{ item.divider }}</p>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="py-12"></div>
      </div>
    </v-container>
  </div>
</template>

<script>
// worksデータファイルのインポート
import { worksData } from '@/data/WorksData'
export default {
  data: () => ({
    selectGenre: "All Genre",
    genres: [ "All Genre", "Melodious Nekochan", "D10RAMA", "Article", "TonEvo", "Micomprocedure", "Music Game", "Other Games", "Others" ],
    works: [
      {
        url: "",
        date: "",
        genre: "",
        color: "",
        title: "",
        text: "",
        divider: "",
      },
    ],
  }),
  computed: {
    items() {
      if(this.selectGenre === "All Genre"){
        return this.works
      } else {
        return this.works.filter((work) => work.genre === this.selectGenre);
      }
    },
  },
  created() {
    this.works = worksData.works;
  },
}
</script>

<style scoped>
.top-slides-bg-circles {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #f8c6c6;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}
.top-slides-bg-circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}
.top-slides-bg-circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}
.top-slides-bg-circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}
.top-slides-bg-circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}
.top-slides-bg-circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}
.top-slides-bg-circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}
.top-slides-bg-circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
.top-slides-bg-circles2 {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles2 li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #adb9de;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles2 li:nth-child(1) {
  left: 80%;
  width: 80px;
  height: 80px;
  animation-delay: 6s;
}
.top-slides-bg-circles2 li:nth-child(2) {
  left: 55%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  animation-duration: 14s;
}
.top-slides-bg-circles2 li:nth-child(3) {
  left: 30%;
  width: 20px;
  height: 20px;
  animation-delay: 16s;
}
.top-slides-bg-circles2 li:nth-child(4) {
  left: 15%;
  width: 60px;
  height: 60px;
  animation-delay: 4s;
  animation-duration: 16s;
}
.top-slides-bg-circles2 li:nth-child(5) {
  left: 40%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
@keyframes animate {
  0% {
    transform: translateY(1000px) rotate(0deg);
    opacity: 0.4;
    border-radius: 20%;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0.2;
    border-radius: 40%;
  }
}
</style>
