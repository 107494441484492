<template>
  <div class="top">
    <ul class="top-slides-bg-circles"></ul>
    <ul class="top-slides-bg-circles2"></ul>
    <v-container fluid>
      <div class="top-slides-bg" ref="getSlidesHeight">
        <div class="top-link">
          <v-row class="mt">
            <v-col cols="12"><p class="topic">Trip</p></v-col>
          </v-row>
          <div class="py-4"></div>
          <v-row>
            <v-col cols="12" v-for="(item, i) in trips" :key="i">
              <v-card  color="rgba(250,250,250,0.5)" class="trip-list">
                <div class="trip-img">
                  <v-img cover v-bind:src="item.src"></v-img>
                </div>
                <div class="trip-list-item">
                  <v-card-title class="py-3 text-h4" style="color: #f26c73">{{
                    item.date
                  }}</v-card-title>
                  <v-card-text class="trip-list-text" style="color: #222">{{
                    item.text
                  }}</v-card-text>
                  <v-btn flat :href=item.url target="_blank" style="color: #f26c73" v-if="item.url !== ''">{{
                    item.jump
                  }}</v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div class="py-12"></div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    trips: [
      {
        src: require("../assets/trips/trip_29.webp"),
        date: "2024/11",
        text: "山形県 米沢市\n福島県 福島市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_28.webp"),
        date: "2024/10",
        text: "富山県 富山市・魚津市・黒部市・立山町\n長野県 長野市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_27.webp"),
        date: "2024/09",
        text: "北海道 石狩振興局 札幌市・千歳市\n宗谷振興局 稚内市・豊富町・猿払村\n胆振振興局 苫小牧市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_26.webp"),
        date: "2024/03",
        text: "石川県 金沢市\n大阪府 大阪市\n京都府 京都市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_25.webp"),
        date: "2024/02",
        text: "山形県 山形市\n秋田県 秋田市\n青森県 青森市\n北海道 函館市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_24.webp"),
        date: "2023/11",
        text: "北海道 石狩振興局 札幌市・千歳市\n十勝振興局 新得町・帯広市・陸別町\n上川振興局 南富良野町・富良野市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_23.webp"),
        date: "2023/07",
        text: "京都府 京都市\n愛知県 名古屋市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_22.webp"),
        date: "2023/05",
        text: "宮城県 南三陸町",
        jump: "旅行記はこちらから",
        url: "https://semicolon0103.hatenablog.com/entry/2023/05/10/180000",
      },
      {
        src: require("../assets/trips/trip_21.webp"),
        date: "2023/03",
        text: "京都府 八幡市・京都市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_20.webp"),
        date: "2022/12",
        text: "北海道 上川振興局 占冠村・旭川市・音威子府村・南富良野町\n宗谷振興局 幌延町・稚内市・礼文町・猿払村・浜頓別町\n十勝振興局 新得町\n石狩振興局 札幌市・千歳市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_19.webp"),
        date: "2022/09",
        text: "北海道 上川振興局 音威子府村・富良野市・南富良野町\n宗谷振興局 幌延町・稚内市\n十勝振興局 新得町・帯広市\n日高振興局 えりも町\n石狩振興局 札幌市・千歳市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_18.webp"),
        date: "2022/05",
        text: "香川県 高松市\n岡山県 岡山市\n京都府 京都市\n愛知県 名古屋市\n岐阜県 大垣市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_17.webp"),
        date: "2022/03",
        text: "岩手県 花巻市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_16.webp"),
        date: "2022/02",
        text: "北海道 オホーツク振興局 北見市・網走市\n上川振興局 音威子府村\n宗谷振興局 幌延町・稚内市\n石狩振興局 札幌市・千歳市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_15.webp"),
        date: "2022/01",
        text: "長野県 茅野市・下諏訪町\n大阪府 大阪市\n京都府 京都市\n石川県 金沢市\n富山県 魚津市・富山市",
        jump: "旅行記はこちらから",
        url: "https://semicolon0103.hatenablog.com/entry/2022/12/04/140000",
      },
      {
        src: require("../assets/trips/trip_14.webp"),
        date: "2021/12",
        text: "北海道 十勝振興局 新得町\n石狩振興局 札幌市\n宗谷振興局 稚内市・幌延町\n上川振興局 音威子府村・名寄市・旭川市\n石狩振興局 小樽市",
        jump: "旅行記はこちらから",
        url: "https://semicolon0103.hatenablog.com/entry/2021_12_1",
      },
      {
        src: require("../assets/trips/trip_13.webp"),
        date: "2021/08",
        text: "北海道 石狩振興局 札幌市\n宗谷振興局 豊富町・幌延町・稚内市\n上川振興局 旭川市\n釧路振興局 釧路市・根室市\n十勝振興局 帯広市・広尾町\n日高振興局 えりも町\n石狩振興局 千歳市",
        jump: "旅行記はこちらから",
        url: "https://semicolon0103.hatenablog.com/entry/2022/12/16/140000",
      },
      {
        src: require("../assets/trips/trip_12.webp"),
        date: "2021/03",
        text: "島根県 出雲市\n鳥取県 鳥取市\n兵庫県 豊岡市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_11.webp"),
        date: "2021/02",
        text: "北海道 石狩振興局 札幌市\n十勝振興局 新得町\n上川振興局 旭川市・剣淵町・和寒町・名寄市\n宗谷振興局 稚内市\n後志振興局 小樽市\n石狩振興局 千歳市",
        jump: "旅行記はこちらから",
        url: "https://semicolon0103.hatenablog.com/entry/2021/12/16/180000_1",
      },
      {
        src: require("../assets/trips/trip_10.webp"),
        date: "2020/03",
        text: "新潟県 新潟市\n石川県 金沢市\n京都府 京都市\n鳥取県 鳥取市\n島根県 出雲市\n北海道 小樽市・札幌市",
        jump: "旅行記はこちらから",
        url: "https://note.com/semicolon0103/n/na5f69a8bf619",
      },
      {
        src: require("../assets/trips/trip_09.webp"),
        date: "2020/02",
        text: "京都府 台東区・新宿区",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_08.webp"),
        date: "2019/09",
        text: "大阪府 豊中市\n兵庫県 相生市・姫路市\n京都府 京都市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_07.webp"),
        date: "2019/04",
        text: "大阪府 大阪市\n京都府 京都市\n石川県 金沢市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_06.webp"),
        date: "2019/02",
        text: "広島県 尾道市\n愛媛県 今治市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_05.webp"),
        date: "2018/09",
        text: "神奈川県 箱根町\n京都府 京都市\n石川県 金沢市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_04.webp"),
        date: "2018/02",
        text: "長野県 諏訪市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_03.webp"),
        date: "2017/09",
        text: "青森県 青森市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_02.webp"),
        date: "2017/02",
        text: "福島県 田村市",
        jump: "旅行記はこちらから",
        url: "",
      },
      {
        src: require("../assets/trips/trip_01.webp"),
        date: "2016/08",
        text: "京都府 京都市\n長野県 茅野市",
        jump: "旅行記はこちらから",
        url: "",
      },
    ],
  }),
}
</script>

<style scoped>
.top-slides-bg-circles {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #f8c6c6;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}
.top-slides-bg-circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}
.top-slides-bg-circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}
.top-slides-bg-circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}
.top-slides-bg-circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}
.top-slides-bg-circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}
.top-slides-bg-circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}
.top-slides-bg-circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
.top-slides-bg-circles2 {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles2 li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #adb9de;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles2 li:nth-child(1) {
  left: 80%;
  width: 80px;
  height: 80px;
  animation-delay: 6s;
}
.top-slides-bg-circles2 li:nth-child(2) {
  left: 55%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  animation-duration: 14s;
}
.top-slides-bg-circles2 li:nth-child(3) {
  left: 30%;
  width: 20px;
  height: 20px;
  animation-delay: 16s;
}
.top-slides-bg-circles2 li:nth-child(4) {
  left: 15%;
  width: 60px;
  height: 60px;
  animation-delay: 4s;
  animation-duration: 16s;
}
.top-slides-bg-circles2 li:nth-child(5) {
  left: 40%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
@keyframes animate {
  0% {
    transform: translateY(1000px) rotate(0deg);
    opacity: 0.4;
    border-radius: 20%;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0.2;
    border-radius: 40%;
  }
}
</style>
