<template>
  <v-app>
    <v-navigation-drawer location="right" width="150" v-model="drawer" class="d-sm-none" style="background: linear-gradient(135deg, #f8c6c6 30%, #fbe0f1 55%, #adb9de 80%);">
      <div class="drawer-title"><router-link to="/" @click="clickTop()"><AioiAoha /></router-link></div>
      <v-list class="drawer-tabs" v-model="model">
        <v-list-item to="/" class="d-none" value="tab0">TOP</v-list-item>
        <v-list-item to="/music" class="drawer-tab" value="tab1">MUSIC</v-list-item>
        <v-list-item to="/discography" class="drawer-tab" value="tab2">DISCOGRAPHY</v-list-item>
        <v-list-item to="/trip" class="drawer-tab" value="tab3">TRIP</v-list-item>
        <v-list-item to="/works" class="drawer-tab" value="tab4">WORKS</v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-app-bar app style="background: linear-gradient(135deg, #f8c6c6 65%, #fbe0f1 75%, #adb9de 95%);">
        <div class="header-area d-none d-sm-flex">
          <div class="header-title"><router-link to="/" @click="clickTop()" aria-label="トップへ戻る"><AioiAoha /></router-link></div>
          <v-tabs fixed-tabs class="tabs" v-model="model">
            <v-tab to="/" class="d-none" value="tab0">Top</v-tab>
            <v-tab to="/music" class="tab" value="tab1">Music</v-tab>
            <v-tab to="/discography" class="tab" value="tab2">DISCOGRAPHY</v-tab>
            <v-tab to="/trip" class="tab" value="tab3">Trip</v-tab>
            <v-tab to="/works" class="tab" value="tab4">Works</v-tab>
          </v-tabs>
        </div>
        <div class="header-area d-flex d-sm-none">
          <v-app-bar-nav-icon color="#f26c73" @click="drawer = true" class="drawer-icon" aria-label="メニューボタン"></v-app-bar-nav-icon>
          <div class="header-title"><router-link to="/" @click="clickTop()"><AioiAoha /></router-link></div>
        </div>
      </v-app-bar>
      <router-view/>
      <v-footer>
        <v-row justify="center" no-gutters>
          <v-col class="text-center mt-8 mb-8" cols="12">
            © {{ new Date().getFullYear() }} — Aioi Aoha
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<style>
  @import "./assets/stylesheet.css";
</style>

<script>
  import AioiAoha from './assets/logo.svg';

  export default {
    components: {
      AioiAoha,
    },
    data () {
      return {
        drawer: false,
        model: 'tab0',
      }
    },
    methods: {
      clickTop: function() {
        this.model = 'tab0';
      },
    },
  }
</script>