<template>
  <div class="top">
    <ul class="top-slides-bg-circles"></ul>
    <ul class="top-slides-bg-circles2"></ul>
    <v-dialog v-model="dialog" transition="fade-transition" width="70%" max-width="800" max-height="80%">
      <v-card>
        <v-list>
          <v-list-item>
            <v-row>
              <v-col cols="12" sm="12" md="7" lg="7" xl="7" xxl="7" class="pb-0 mb-0">
                <v-card-text class="discography-list-src pb-0">
                  <v-img :src="music.src"></v-img>
                </v-card-text>
                <v-card-text class="discography-list-src py-1 pb-0">
                  <div style="text-align: center;" >
                    {{ music.title }}
                    <br>
                    <p v-if="music.art_link === ''">
                      <a style="color: #000000">illust: {{ music.art }}</a>
                    </p>
                    <p v-else>
                      <a :href="music.art_link" target="_blank" style="color: #000000">illust: </a>
                      <a :href="music.art_link" target="_blank">{{ music.art }}</a>
                    </p>
                  </div>
                </v-card-text>
              </v-col>
              <v-col cols="12" sm="12" md="5" lg="5" xl="5" xxl="5" class="d-flex flex-column text-center">
                <div class="music-streaming-popup-list mt-3 d-flex flex-row justify-center">
                  <a :href="music.Spotify" target="_blank">
                    <v-img src="../assets/streaming/music-service_spotify.webp" alt="spotify" height="45"></v-img>
                  </a>
                </div>
                <div class="music-streaming-popup-list mt-3 d-flex flex-row justify-center">
                  <a :href="music.Apple_Music" target="_blank">
                    <v-img src="../assets/streaming/music-service_applemusic_listen.webp" alt="spotify" height="45"></v-img></a>
                </div>
                <div class="music-streaming-popup-list mt-3 d-flex flex-row justify-center">
                  <a :href="music.YouTube_Music" target="_blank">
                    <v-img src="../assets/streaming/music-service_youtubemusic_new.webp" alt="spotify" height="45"></v-img></a>
                </div>
                <div class="music-streaming-popup-list mt-3 d-flex flex-row justify-center">
                  <a :href="music.LINE_Music" target="_blank">
                    <v-img src="../assets/streaming/music-service_linemusic.webp" alt="spotify" height="45"></v-img></a>
                </div>
                <div class="music-streaming-popup-list mt-3 d-flex flex-row justify-center">
                  <a :href="music.Amazon_Music_streaming" target="_blank">
                    <v-img src="../assets/streaming/music-service_amazon.webp" alt="spotify" height="45"></v-img></a>
                </div>

                <v-card-text
                    class="discography-list-text pt-0 mt-10 pb-0 mb-0"
                    style="color: #222"
                ><span v-html="music.text"></span
                ></v-card-text>
                <v-card-text
                    class="text-right text-caption text-bottom pt-0 mt-0 pb-0 mb-1"
                    style="color: #777"
                >{{ music.date }}</v-card-text
                >
              </v-col>

            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <div class="top-slides-bg" ref="getSlidesHeight">
        <div class="top-link">
          <v-row class="mt">
            <v-col cols="12"><p class="topic">Music</p></v-col>
            <v-col cols="1" sm="6" md="8" lg="8" xl="8" xxl="8"></v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4" xxl="4">
              <v-select v-model="selectCategory" :items="categories" class="pulldown"></v-select>
            </v-col>
          </v-row>
          <div class="py-2"></div>
          <v-row>
            <v-col cols="6" sm="4" md="3" lg="3" xl="3" xxl="3" v-for="(item, i) in items" :key="i" class="music-list">
              <v-card height="100%" class="links" @click="dialogOn(item)">
                <v-img v-bind:src="item.src"></v-img>
                <v-card-title class="text-left text-subtitle-2 mt-1 pa-0 pl-3 mb-0 pb-0" :style="{color: item.color}">{{
                    item.category
                  }}</v-card-title>
                <v-card-title class="music-streaming-list-title mt-0 pt-0 pl-0 pr-0 pb-2" style="color: #222">{{
                    item.title
                  }}</v-card-title>
                <v-card-text class="text-right text-caption text-bottom" style="color: #777">{{
                    item.date
                  }}</v-card-text>
              </v-card>
              <div class="py-3"></div>
            </v-col>
          </v-row>
        </div>
        <a href="/more_music" class="text-h5">More...</a>
        <div class="py-4"></div>
      </div>
    </v-container>
  </div>
</template>

<script>
// 楽曲データファイルのインポート
import { musicData } from '@/data/MusicData'
export default {
  components: {},
  data: () => ({
    dialog: false,
    selectCategory: "All Category",
    categories: [ "instrumental" ],
    musics: {
      Spotify: "",
      Apple_Music: "",
      YouTube_Music: "",
      LINE_Music: "",
      Amazon_Music_streaming: "",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "",
      src: "",
      category: "",
      color: "",
      title: "",
      text: "",
      art: "",
      art_link: "",
      date: "",
    },

  }),
  computed: {
    items() {
      if(this.selectCategory === "All Category"){
        return this.musics
      } else {
        return this.musics.filter((music) => music.category === this.selectCategory);
      }
    },
  },
  methods: {
    dialogOn(content) {
      this.music = content;
      this.dialog = true;
    },
  },
  created() {
    this.musics = musicData.musics;
  },
}
</script>

<style scoped>
.top-slides-bg-circles {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #f8c6c6;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}
.top-slides-bg-circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}
.top-slides-bg-circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}
.top-slides-bg-circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}
.top-slides-bg-circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}
.top-slides-bg-circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}
.top-slides-bg-circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}
.top-slides-bg-circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
.top-slides-bg-circles2 {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles2 li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #adb9de;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles2 li:nth-child(1) {
  left: 80%;
  width: 80px;
  height: 80px;
  animation-delay: 6s;
}
.top-slides-bg-circles2 li:nth-child(2) {
  left: 55%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  animation-duration: 14s;
}
.top-slides-bg-circles2 li:nth-child(3) {
  left: 30%;
  width: 20px;
  height: 20px;
  animation-delay: 16s;
}
.top-slides-bg-circles2 li:nth-child(4) {
  left: 15%;
  width: 60px;
  height: 60px;
  animation-delay: 4s;
  animation-duration: 16s;
}
.top-slides-bg-circles2 li:nth-child(5) {
  left: 40%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
@keyframes animate {
  0% {
    transform: translateY(1000px) rotate(0deg);
    opacity: 0.4;
    border-radius: 20%;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0.2;
    border-radius: 40%;
  }
}
</style>
